import { getPlatformType } from "../../components/Onboarding/onboarding.helper";
import { config } from "./../constants";
import { handleFlutterAction } from "./flutter.utils";

declare const window: any;

const getDefaultOptions = () => {
  if (!window?.store) {
    return;
  }

  const state = window?.store?.getState();

  return {
    source: getPlatformType(state?.onboarding?.platform),
    host: window.location.host,
    page: window.location.href?.replace(
      /token=(\d|\w|\W)+/gi,
      "token=XXXXXXXXXX"
    ),
    userId: state?.auth?.userInfo?.userId || "N/A",
    timestamp: Date.now() / 1000,
    userAgent: window?.navigator?.userAgent,
    eventVersion: "web_" + config?.eventVersion,
  };
};

export const pushGA = (
  name: string,
  options: Record<any, string | number> = {}
) => {
  const _options = { ...getDefaultOptions(), ...options };

  if (window?.gtag) {
    window.gtag("event", name, _options);
  }
};

export const pushMoEngage = (
  name: string,
  options: Record<any, string | number> = {}
) => {
  const _options = { ...getDefaultOptions(), ...options };

  if (window?.Moengage) {
    window.Moengage.track_event(name, _options);
  }
};

export const pushPixel = (
  name: string,
  options: Record<any, string | number> = {}
) => {
  const _options = { ...getDefaultOptions(), ...options };

  if (window?.fbq) {
    window.fbq("track", name, _options);
  }
};

export const pushFirebase = (
  name: string,
  options: Record<any, string | number> = {}
) => {
  const _options = { ...getDefaultOptions(), ...options };

  try {
    // @ts-ignore
    // AndroidAction.sendFirebase(name, JSON.stringify(_options));

    handleFlutterAction({
      functionName: "sendFirebase",
      paramsArray: [name, JSON.stringify(_options)],
    });
    return;
  } catch (error: any) {
    console.log("Error: [Android]", error?.message);
  }

  try {
    // @ts-ignore
    sendFirebase(name, JSON.stringify(options));
    return;
  } catch (error: any) {
    console.log("Error: [iOS]", error?.message);
  }
};

export const pushFacebook = (
  name: string,
  options: Record<any, string | number> = {}
) => {
  const _options = { ...getDefaultOptions(), ...options };

  try {
    // @ts-ignore
    // AndroidAction.sendFacebook(name, JSON.stringify(_options));

    handleFlutterAction({
      functionName: "sendFacebook",
      paramsArray: [name, JSON.stringify(_options)],
    });
    return;
  } catch (error: any) {
    console.log("Error: [Android]", error?.message);
  }

  try {
    // @ts-ignore
    sendFacebook(name, JSON.stringify(options));
    return;
  } catch (error: any) {
    console.log("Error: [iOS]", error?.message);
  }
};

export const pushFacebookWeb = (
  name: string,
  options: Record<any, string | number | boolean> = {}
) => {
  try {
    const _options = { ...getDefaultOptions(), ...options };

    // @ts-ignore
    fbq("trackCustom", name, _options);
  } catch (error: any) {
    console.log("Error: [Web]", error?.message);
  }
};

export const pushAppsFlyer = (
  name: string,
  options: Record<any, string | number> = {}
) => {
  const _options = { ...getDefaultOptions(), ...options };

  try {
    // @ts-ignore
    // AndroidAction.sendAppsFlyer(name, JSON.stringify(_options));

    handleFlutterAction({
      functionName: "sendAppsFlyer",
      paramsArray: [name, JSON.stringify(_options)],
    });
    return;
  } catch (error: any) {
    console.log("Error [Android]: ", error?.message);
  }

  try {
    // @ts-ignore
    sendAppsFlyer(name, JSON.stringfy(_options));
    return;
  } catch (error: any) {
    console.log("Error [iOS]: ", error?.message);
  }
};

export const pushApp = (
  name: string,
  options: Record<any, string | number> = {}
) => {
  const _options = { ...getDefaultOptions(), ...options };

  pushFirebase(name, _options);
  pushAppsFlyer(name, _options);
  pushFacebook(name, _options);
};

export const pushWeb = (
  name: string,
  options: Record<any, string | number> = {}
) => {
  const _options = { ...getDefaultOptions(), ...options };

  pushGA(name, _options);
  pushMoEngage(name, _options);
};

export const pushAll = (
  name: string,
  options: Record<any, string | number> = {}
) => {
  const _options = { ...getDefaultOptions(), ...options };
  pushGA(name, _options);
  pushMoEngage(name, _options);
  pushFirebase(name, _options);
  pushAppsFlyer(name, _options);
  pushFacebook(name, _options);
  pushPixel(name, _options);
};

export const pushAllExceptMoengage = (
  name: string,
  options: Record<any, string | number> = {}
) => {
  const _options = { ...getDefaultOptions(), ...options };
  pushGA(name, _options);
  pushFirebase(name, _options);
  pushAppsFlyer(name, _options);
  pushFacebook(name, _options);
  pushPixel(name, _options);
};

const _default = {
  pushGA,
  pushMoEngage,
  pushFirebase,
  pushAppsFlyer,
  pushFacebook,
  pushPixel,
  pushFacebookWeb,
  pushApp,
  pushWeb,
  pushAll,
  pushAllExceptMoengage,
};

export default _default;
