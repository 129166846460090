export const handleFlutterAction = ({
  functionName,
  paramsArray,
}: {
  functionName: string;
  paramsArray: Array<any>;
}) => {
  try {
    // @ts-ignore
    window.flutter_inappwebview.callHandler(
      "AndroidAction",
      functionName,
      ...paramsArray
    );
  } catch {
    console.log("flutter error");
  }
};
