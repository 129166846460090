import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";

import { useSelector, useDispatch } from "react-redux";
import { useHasMounted } from "../../../../hooks";

import { logoutUser } from "../../../../../slices/auth-slice";
import { routes } from "../../../../constants";

import { imageParser } from "../../../../utils/image.utils";

import Button from "./../../../Button/Button";
import DropLinks from "../../DropLinks/DropLinks";
import Wrapper from "./../../Wrapper/Wrapper";
import { OTP_VERIFIED } from "../../../../constants/enums";
import * as ga from "../../../../../components/common/UtilsGA";
import { clearOnboarding } from "../../../../../slices/onboarding.slice";
import { clearTracker } from "../../../../../slices/tracker.slice";
import { buttons } from "../header.trans";
import { handleFlutterAction } from "../../../../utils/flutter.utils";

export const AuthButtons = ({
  wrapperClass,
  linebreak,
  popupLeft,
  popupRight,
  popupWidth,
  disableClicks,
}: {
  wrapperClass?: string;
  linebreak?: boolean;
  popupLeft?: string;
  popupRight?: string;
  popupWidth?: string;
  disableClicks?: boolean;
}) => {
  const router = useRouter();
  const dispatch = useDispatch<any>();
  const mounted = useHasMounted();

  const { t } = useTranslation("header");
  const { userInfo, sendOTP } = useSelector(
    (state: { auth: any }) => state?.auth
  );
  const { dsaPlan } = useSelector((state: any) => state.onboarding);
  const { sales_channel, sales_agent } = useSelector(
    (state: any) => state.utmReducer
  );

  const isDsaPlan = dsaPlan?.toLowerCase()?.includes("dsa");

  const handleLogout = () => {
    dispatch(clearOnboarding());
    dispatch(logoutUser());
    dispatch(clearTracker());

    try {
      // @ts-ignore
      // AndroidAction.logout();

      handleFlutterAction({
        functionName: "logout",
        paramsArray: [],
      });
      return;
    } catch (error: any) {
      console.log("Error: [Android] Logout", error?.message);
    }

    try {
      // @ts-ignore
      logout();
      return;
    } catch (error: any) {
      console.log("Error: [iOS] Logout", error?.message);
    }

    router.push(
      isDsaPlan
        ? `/${dsaPlan}?sales_channel=${sales_channel}&sales_agent=${sales_agent}`
        : routes.HOME_PAGE
    );
  };

  if (mounted)
    return (
      <>
        <Wrapper
          spacing="20px"
          className={wrapperClass || "web-buttons"}
          justify={linebreak && "left"}
          align="center"
          flexDirection={linebreak && "column"}
        >
          {userInfo?.user ? (
            <DropLinks
              popupLeft={popupLeft}
              popupRight={popupRight}
              popupWidth={popupWidth}
              button={
                <Wrapper align="center" spacing="10px">
                  <img
                    height={"30px"}
                    src={imageParser("layout/profile.svg")}
                    alt="_"
                  />
                  <div className="auth-text">{userInfo?.user?.name}</div>
                </Wrapper>
              }
              links={
                disableClicks
                  ? []
                  : [
                      {
                        text: "My Plans",
                        url: routes.MY_PLANS_PAGE,
                      },
                      {
                        text: (
                          <Wrapper align="center" spacing="10px">
                            <span>Logout </span>
                          </Wrapper>
                        ),
                        action: handleLogout,
                      },
                    ]
              }
            />
          ) : sendOTP?.phone && sendOTP?.status === OTP_VERIFIED ? (
            <DropLinks
              popupLeft={"-50px"}
              popupRight={popupRight}
              popupWidth={"235px"}
              button={
                <Wrapper align="center" spacing="10px">
                  <img
                    height={"30px"}
                    src={imageParser("layout/profile.svg")}
                    alt="_"
                  />
                  <div className="auth-text">
                    {sendOTP?.phone?.toString()?.slice(0, 5) + "XXXXX"}
                  </div>
                </Wrapper>
              }
              links={
                disableClicks
                  ? []
                  : [
                      {
                        text: "Complete your details",
                        url: routes.SIGNUP_PAGE,
                      },
                      {
                        text: (
                          <Wrapper align="center" spacing="10px">
                            <span>Logout </span>
                          </Wrapper>
                        ),
                        action: handleLogout,
                      },
                    ]
              }
            />
          ) : (
            <>
              <Button
                variant="contained"
                label={t(buttons.signup)}
                color="secondary"
                className="button-signup"
                size="xs"
                style={{ boxShadow: "none" }}
                onClick={() => {
                  window?.Moengage?.track_event("header_login_click", {});
                  ga.event({
                    action: "header_login_click",
                    params: {},
                  });
                  router.push(routes.HERO_SIGNUP_PAGE);
                }}
              />
              <Button
                variant="outline"
                label={t(buttons.login)}
                size="xs"
                className="button-login"
                style={{
                  boxShadow: "none",
                  background: "transparent",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => {
                  window?.Moengage?.track_event("header_login_click", {});
                  ga.event({
                    action: "header_login_click",
                    params: {},
                  });
                  router.push(routes.LOGIN_PAGE);
                }}
              />
            </>
          )}
        </Wrapper>
      </>
    );

  return <></>;
};

export default AuthButtons;
